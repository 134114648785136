export const environment = {
  production: true,
  useEmulators: false,
  spotify: {
    apiUrl: 'https://api.spotify.com/v1/me',
    clientId: 'ef247ad2b6a6480ab274d9f32b27dfe9',
    redirectURI: 'https://tracklists.io/login',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyCZ2Sc4pjV_1gGaHXazCOit3Vs3PLm-Mv4',
    authDomain: 'listy-prod.firebaseapp.com',
    projectId: 'listy-prod',
    storageBucket: 'listy-prod.appspot.com',
    messagingSenderId: '230582633068',
    appId: '1:230582633068:web:afc13a67c5678ab6454e49',
    measurementId: 'G-S6KYFQPR9M',
  },
  sentry: {
    dsn: 'https://7d5d8dd921ab4f849d0df2c5e18c1367@o1005951.ingest.sentry.io/6174069',
  },
};
